import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'
import { dispatch } from 'store'

const locationMaps = {
  Nlbh3LYShm62wScLmlkd:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1697300026/chosun/locationMapDowntown.jpg',
  B6jZ7mM0lrvwUkPqfqZf: locationMap,
}

const locationMapsMobile = {
  Nlbh3LYShm62wScLmlkd:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1697300026/chosun/mobileMapDowntown.jpg',
  B6jZ7mM0lrvwUkPqfqZf: mobileMap,
}

const locationMapsUrl = {
  Nlbh3LYShm62wScLmlkd: 'https://maps.app.goo.gl/1QGzg4WXq9hGvK8T8',
  B6jZ7mM0lrvwUkPqfqZf: 'https://maps.app.goo.gl/WZF7zQxpcKxWSA2x9',
}

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <CFLink href={locationMapsUrl[locationId]}>
              <CFImage
                src={locationMapsMobile[locationId]}
                w="100%"
                alt="Chosun BBQ Map"
              />
            </CFLink>
          </MobileScreen>
          <DefaultScreen>
            <CFLink href={locationMapsUrl[locationId]} newTab>
              <CFImage
                src={locationMaps[locationId]}
                w="100%"
                cover
                alt="Chosun BBQ Map"
              />
            </CFLink>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
