import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  MobileScreen,
  LocationSelect,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  viewMenu,
} from 'images'
import { dispatch } from 'store'

// const locationMenus = {
//   Nlbh3LYShm62wScLmlkd:
//     'https://order.codefusion.tech/restaurants/2LvPFXuJPuh1Ieji38v1/locations/Nlbh3LYShm62wScLmlkd',
//   B6jZ7mM0lrvwUkPqfqZf:
//     'https://order.codefusion.tech/restaurants/2LvPFXuJPuh1Ieji38v1/locations/B6jZ7mM0lrvwUkPqfqZf',
// }
export default () => {
  return (
    <CFSelect
      selector={{
        locationId: dispatch.restaurant.getSelectedLocationId,
        pdfMenuUrl: dispatch.restaurant.getPdfMenuUrl,
      }}
    >
      {({ locationId, pdfMenuUrl }) => {
        let orderButtonLink = null
        let orderButton = null
        if (locationId === 'B6jZ7mM0lrvwUkPqfqZf') {
          orderButtonLink = pdfMenuUrl
          orderButton = viewMenu
        } else {
          orderButtonLink = pdfMenuUrl
          orderButton = viewMenu
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView
                h="100VH"
                w="100%"
                image={`url(${mobileHero}) center / cover no-repeat`}
                boxShadow="0 2px 2px rgba(0,0,0,.5)"
                column
                center
              >
                <CFView textCenter column center w="100%">
                  <CFView column center>
                    <CFImage
                      w="85%"
                      src={mobileHeroText}
                      alt="Chosun Korean BBQ hero text"
                      zIndex={98}
                    />
                    <CFView mt="25px">
                      <LocationSelect />
                    </CFView>
                    <CFView mt="35px" pulsate>
                      <OrderPickupButton />
                    </CFView>
                    <a href={orderButtonLink}>
                      <CFView hover>
                        <CFImage
                          src={orderButton}
                          w="95%"
                          maxWidth="300px"
                          alt="About"
                        />
                      </CFView>
                    </a>
                  </CFView>
                </CFView>
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView
                h="100vh"
                maxHeight="700px"
                w="100%"
                image={`url(${hero}) top/ cover no-repeat`}
                zIndex={90}
                column
                center
              >
                <CFView column center h="650px">
                  <CFView column center mt="80px">
                    <CFImage
                      h="320px"
                      src={heroText}
                      alt="Chosun Korean BBQ hero text"
                      zIndex={98}
                    />
                    <CFSelect
                      selector={dispatch.restaurant.getHasMultipleLocations}
                    >
                      <CFView mt="30px">
                        <LocationSelect />
                      </CFView>
                    </CFSelect>
                  </CFView>
                  <CFView mt="30px" pulsate>
                    <OrderPickupButton />
                  </CFView>
                  <a href={orderButtonLink}>
                    <CFView hover mt="8px">
                      <CFImage src={orderButton} maxWidth="320px" alt="About" />
                    </CFView>
                  </a>
                </CFView>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
