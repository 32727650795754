import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mt="25px">
          <Parallax
            bgImage={mobileAbout}
            bgImageAlt="Entree Dish"
            strength={250}
          >
            <div style={{ height: '365px' }} />
          </Parallax>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          maxWidth="1400px"
          w="100%"
          h="550px"
          image={`url(${aboutParallax}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
