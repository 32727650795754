import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#fff" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/ChosunKoreanBBQVancouver/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Chosun Korean BBQ Facebook"
              />
            </CFLink>
            {/*<CFLink href="https://www.yelp.ca/biz/sushi-musa-langley-city">
              <CFImage w="45px" pr="10px" src={yelp} alt="Chosun Korean BBQ Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Chosun Korean BBQ Zomato"
              />
            </CFLink>*/}
            <CFLink href="https://www.instagram.com/explore/locations/236686217/chosun-korean-bbq-restaurant-vancouver/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Chosun Korean BBQ Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#fff" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/ChosunKoreanBBQVancouver/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Chosun Korean BBQ Facebook"
                hover
              />
            </CFLink>
            {/*<CFLink href="https://www.yelp.ca/biz/sushi-musa-langley-city">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Chosun Korean BBQ Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Chosun Korean BBQ Zomato"
                hover
              />
            </CFLink>*/}
            <CFLink href="https://www.instagram.com/explore/locations/236686217/chosun-korean-bbq-restaurant-vancouver/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Chosun Korean BBQ Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
