export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1697301048/chosun/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1614625583/sushimusa/about.jpg'
export const aboutParallax =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620855562/chosun/aboutParallax.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620945163/chosun/contactbg_9_1.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620944913/chosun/Mask_Group_2_1.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620944907/chosun/Group_1072.png'
export const logo =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614366764/logo_1_rhgea0.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1621026246/chosun/locationMap.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1621028780/chosun/mobileAbout.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620945450/chosun/contactbgMobile_4_1.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1621027901/chosun/mobileHero.jpg'
export const mobileLocationsBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1697300796/chosun/mobileBg.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620945419/chosun/locationMapMobile_6_1.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620945268/chosun/Group_1072_1.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627420367/chosun/mobilePromotion2.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1697301048/chosun/orderPickupButton2.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627420641/chosun/promotions2.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620945086/chosun/foodGallery_7_1.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1621026678/chosun/mobileGallery.jpg'
export const promo1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/promo1.jpg'
export const promo2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/promo2.jpg'
export const promo3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/promo3.jpg'
export const gallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery1.jpg'
export const gallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery2.jpg'
export const gallery3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery3.jpg'
export const gallery4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery4.jpg'
export const gallery5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery5.jpg'
export const gallery6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery6.jpg'
export const gallery7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery7.jpg'
export const gallery8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620856460/chosun/gallery8.jpg'
